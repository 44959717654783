import React, { useState } from "react";
import { YoutubeButton } from "../ButtonIcon";

const Video = ({ videoId, videoName }) => {
  const [load, setLoad] = useState(false);

  const onClick = () => setLoad(true);

  return (
    <div
      className="video-responsive"
      onClick={onClick}
      style={
        (!load && {
          backgroundImage: `url('/static/${videoId}.webp')`,
        }) ||
        {}
      }
    >
      {(load && (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?loop=1&amp;iv_load_policy=3&amp;rel=0&amp;modestbranding=1`}
          frameborder="0"
          allowfullscreen="allowfullscreen"
          title={videoName}
        />
      )) || <YoutubeButton className="yt-play-button" />}
    </div>
  );
};

export default Video;
