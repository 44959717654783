import React from 'react'

export const ButtonIconGroup = ({ children, noWrap, flexStart, center, className }) => {
  return (
    <div
      className={`button-icon-group ${noWrap ? 'no-wrap' : ''} ${flexStart ? 'flex-start' : ''} ${center ? 'center' : ''} ${className || ''}`}
      style={{ paddingTop: 3 }}
    >
      {children}
    </div>
  )
}
