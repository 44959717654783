import React from 'react'
import TextTruncate from 'react-text-truncate'
import { Button, Responsive, Icon, Segment } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import { unescape, stripHtml } from '../../utils/helpers'

const almostInfinity = 9999999

const ButtonTrucate = ({ t, onLoadAll }) => {
  return (
    <Segment basic vertical textAlign='center'>
      <Button size='large' color='red' icon labelPosition='right' inverted onClick={onLoadAll}>
        {t('text-long.more')}
        <Icon name='plus' />
      </Button>
    </Segment>
  )
}

const ArrowTrucate = ({ onLoadAll }) => {
  return (
    <Icon className='ta-arrow-1-right' color='red' onClick={onLoadAll} link />
  )
}

class TextLong extends React.Component {
  state = { lineLimit: this.props.line }

  static defaultProps = {
    ...TextLong.defaultProps,
    line: 10
  }

  handleLoadAll = () => {
    this.setState({ lineLimit: almostInfinity })
  }

  render () {
    const { text, breakpoint, t, simple } = this.props

    return (
      <span>
        {breakpoint ? (
          <span>
            <Responsive maxWidth={breakpoint - 1} className='text-long'>
              <TextTruncate
                text={stripHtml(unescape(text))}
                line={this.state.lineLimit}
                textTruncateChild={simple ? (
                  <ArrowTrucate t={t} onLoadAll={this.handleLoadAll} />
                ) : (
                  <ButtonTrucate t={t} onLoadAll={this.handleLoadAll} />
                )}
              />
            </Responsive>
            <Responsive as='p' minWidth={breakpoint} dangerouslySetInnerHTML={{ __html: text }} />
          </span>
        ) : (
          <TextTruncate
            text={stripHtml(unescape(text))}
            line={this.state.lineLimit}
            className='text-long'
            textTruncateChild={simple ? (
              <ArrowTrucate t={t} onLoadAll={this.handleLoadAll} />
            ) : (
              <ButtonTrucate t={t} onLoadAll={this.handleLoadAll} />
            )}
          />
        )
        }
      </span>
    )
  }
}

export default withTranslation()(TextLong)
