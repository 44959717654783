import React, { Component } from 'react'
import Slider from 'react-slick'
import { Segment, Header, Button, Grid, Container, Divider, Icon } from 'semantic-ui-react/dist/commonjs'
import { Link } from '../../locales'
import { withTranslation } from 'react-i18next'

import ItemCard from './ItemCard'

class ItemSlider extends Component {
  next = () => {
    this.slider.slickNext()
  }

  prev = () => {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      infinite: true,
      arrows: false,
      centerPadding: '0px',
      lazyLoad: true,
      slidesToShow: 5,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 590,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    const { label: { black, red }, items, service, countrySlug, t, i18n } = this.props
    return (
      <Grid.Column>
        <Segment basic vertical className='gradient' inverted padded='very'>
          <Container>
            <Grid padded>
              <Grid.Column floated='left' computer={14} tablet={14} mobile={16}>
                <Header as='h2'>{black}<span className='tour-red'> {red}</span></Header>
              </Grid.Column>
              <Grid.Column floated='right' computer={2} tablet={2} mobile={16} textAlign='right'>
                <i className='icon ta-arrow-1-left large red' onClick={this.prev} style={{ cursor: 'pointer' }} />
                <i className='icon ta-arrow-1-right large red' onClick={this.next} style={{ cursor: 'pointer' }} />
              </Grid.Column>
            </Grid>
            <Divider clearing hidden fitted />
          </Container>
          <Slider ref={c => (this.slider = c)} {...settings} >
            {items.map(({ node }) => <ItemCard key={node.id} node={node} />)}
          </Slider>
        </Segment>
        <Segment basic textAlign='center' className='lightgrey' inverted style={{ padding: '2em 0 5em 0' }}>
          <Link
            to={`/destinations/${countrySlug ? countrySlug : 'all'}/${service}`}
            lang={i18n.language}
            aria-label='all countries'
          >
            <Button size='large' color='red' icon labelPosition='right' inverted>
              {t('text-long.more')}
              <Icon className='ta-arrow-1-right' />
            </Button>
          </Link>
        </Segment>
      </Grid.Column>
    )
  }
}

export default withTranslation()(ItemSlider)
