import React from 'react'

export const ButtonIcon = ({ title, icon, active, disabled, invert, inline, square, className, onClick }) => {
  return (
    <div
      onClick={disabled ? null : onClick}
      className={`button-icon ${className} ${active ? 'active' : ''} ${disabled ? 'disabled' : ''} ${invert ? 'invert' : ''} ${inline ? 'inline' : ''} ${square ? 'square' : ''} ${icon ? '' : 'no-icon'}`}>
      {icon && <i className={`icon ${disabled ? 'disabled' : ''} ${icon}`} />}
      <p className='title'>{title}</p>
    </div>
  )
}
